header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5%;
    background-color: #f39c2b;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 99;
    >a{
        text-decoration: none;
        color: #FFFFFF;
        font-size: 18px;
    }
    .navigation{
        display: flex;
        justify-content: flex-end;
        gap: 15px;
    }
    .header-action{
        svg{
            width: 40px;
            height: 40px;
            stroke: #FFFFFF;
            fill: #FFFFFF;
        }
        &:hover{
            svg{
                stroke: black;
                fill: black;
            }
        }
    }
}

.auth-form{
    display: flex;
    flex-direction: column;

    .input-field{
        margin-bottom: 10px;
        input{
            width: 100%;
            height: 48px !important;
            color: black !important;
            border: 1px solid #ececec;
            border-radius: 4px;
            font-size: 16px !important;
            padding: 5px 10px;
        }
        .error{
            height: 16px;
            font-size: 14px;
            color: red;
        }
    }

    button{
        height: 48px;
        background-color: #f39c2b;
        border-radius: 10px;
        border: none;
        color: #FFFFFF;
        cursor: pointer;
        &:disabled{
            opacity: 0.5;
            cursor: initial;
        }
    }
}

