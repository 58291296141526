.modal-bg{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .modal{
        background-color: #fff;
        width: 50%;
        padding: 20px 50px;
        border-radius: 10px;
        box-shadow: 0px 0px 5px black;
        .tabs{
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            .tab{
                padding: 5px 20px;
                background-color: rgb(178, 241, 220);
                cursor: pointer;
                &.active{
                    border-bottom: 2px solid red;
                }
                &:hover{
                    background-color: rgb(133, 187, 169);
                }
            }
        }
    }
}